import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Pagination from '../components/pagination'
import ArticlePreview from '../components/article-preview'

class ConnectedCarNewsList extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulNfnPost.edges')
    
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={'Connected Car News | ' + siteTitle} />
          <div className="wrapper">
            <h2 className="section-headline">Connected Car News</h2>
            <Pagination pageContext={this.props.pageContext} pagePath="/connected-car-news/" />
            <ul className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ul>
          </div>
          <Pagination pageContext={this.props.pageContext} pagePath="/connected-car-news/" />
        </div>
      </Layout>
    )
  }
}

export default ConnectedCarNewsList

export const pageQuery = graphql`
  query ConnectedCarNewsListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNfnPost(
      sort: { fields: [createdLocal], order: DESC }
      filter: {feedName: {eq: "Connected Car"}}
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          postTitle
          slug
          publishDate
          createdLocal
          feedName
          postSummary {
            childMarkdownRemark {
              html
            }
          }
        }
      }
	}
  }
`